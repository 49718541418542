import React from 'react';
import ReactDOM from 'react-dom';
import 'reflect-metadata';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import esES from 'antd/lib/locale/es_ES';
import 'index.css';
import 'antd-customized.less';
import 'tools/i18n/i18n';
import SessionProvider from 'tools/session/session-provider.component';
import { MainFragment } from 'fragments/main/main.fragment';

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <ConfigProvider locale={esES}>
                <SessionProvider>
                    <MainFragment />
                </SessionProvider>
            </ConfigProvider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root'),
);
