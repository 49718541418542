import axios from 'axios';
import { REACT_APP_API_BASE_URL } from 'constants/env';
import { Session } from 'tools/session/session';
import { AccountDto, AccountsService } from 'services/accounts/accounts.service';

export class DolibarrAccountsService implements AccountsService {
    constructor(private session: Session) {}

    async getAllAccounts(): Promise<AccountDto[]> {
        const response = await axios.get(REACT_APP_API_BASE_URL + `/bankaccounts`, {
            headers: { DOLAPIKEY: this.session.getToken() },
            params: {
                sqlfilters: 't.clos=0',
            },
        });
        return response.data.map(
            (item: any): AccountDto => {
                return { id: item.id, name: item.label, type: item.type, balance: item.balance };
            },
        );
    }
}
