import React from 'react';
import 'fragments/sale-detail/sale-detail.scss';
import { SaleDetailFragmentProps } from 'fragments/sale-detail/interfaces';
import { useSaleDetailController } from 'fragments/sale-detail/sale-detail.controller';
import { Button, Form, PageHeader, Select, Space, Typography, Spin, Input, InputNumber } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import ModalForm from 'components/modal-form/modal-form.component';

export const SaleDetailFragment: React.FC<SaleDetailFragmentProps> = (props) => {
    const { useController = useSaleDetailController } = props;
    const { id } = useParams<{ id: string }>();
    const controller = useController(Number(id));
    const history = useHistory();
    const [paymentForm] = Form.useForm();
    const [shippingForm] = Form.useForm();

    const PaymentData = (
        <div>
            <Typography.Title level={3}>Datos del pago</Typography.Title>
            <Space direction={'vertical'}>
                <Typography.Text> {`Fecha: ${controller.paymentData?.date}`} </Typography.Text>
                {/*<Typography.Text> {`Cuenta: ${controller.paymentData?.accountName}`} </Typography.Text>*/}
                <Typography.Text> {`Monto: $${controller.paymentData?.amount}`} </Typography.Text>
            </Space>
        </div>
    );

    const PaymentButton = (
        <Button size={'large'} type={'primary'} block onClick={controller.onAddPaymentPressed}>
            Registrar Pago
        </Button>
    );

    const AddPaymentModal = (
        <ModalForm
            form={paymentForm}
            layout={'vertical'}
            initialValues={controller.paymentFormData}
            isVisible={controller.isAddPaymentVisible}
            isLoading={controller.isAddPaymentLoading}
            onCancel={controller.onCloseAddPayment}
            onFinish={controller.onAddPaymentSubmit}
        >
            <Form.Item label={'Cuenta'} name="accountId" rules={[{ required: true }]}>
                <Select options={controller.accountOptions}>
                    {controller.accountOptions.map((option) => {
                        return (
                            <Select.Option key={option.key} value={option.value}>
                                {option.label}
                            </Select.Option>
                        );
                    })}
                </Select>
            </Form.Item>
        </ModalForm>
    );

    const ShippingButton = (
        <Button size={'large'} type={'primary'} block onClick={controller.onAddShippingPressed}>
            Registrar Entrega
        </Button>
    );

    const ShippingData = (
        <div>
            <Typography.Title level={3}>Datos de la entrega</Typography.Title>
            <Space direction={'vertical'}>
                {controller.shippingData?.map((data, index) => {
                    return (
                        <Typography.Text
                            key={index}
                        >{`${data.date} | Cant: ${data.quantity} | ${data.label}`}</Typography.Text>
                    );
                })}
                {ShippingButton}
            </Space>
        </div>
    );

    const AddShippingModal = (
        <ModalForm
            form={shippingForm}
            layout={'vertical'}
            initialValues={controller.shippingFormData}
            isVisible={controller.isAddShippingVisible}
            isLoading={controller.isAddShippingLoading}
            onCancel={controller.onCloseAddShipping}
            onFinish={controller.onAddShippingSubmit}
        >
            <Form.Item label={'Almacen'} name="warehouse_id" rules={[{ required: true }]}>
                <Select options={controller.warehouseOptions}>
                    {controller.warehouseOptions.map((option) => {
                        return (
                            <Select.Option key={option.key} value={option.value}>
                                {option.label}
                            </Select.Option>
                        );
                    })}
                </Select>
            </Form.Item>

            <Form.Item label={'Lote'} name="lot" rules={[{ required: true }]}>
                <Input autoComplete={'off'} />
            </Form.Item>

            <Form.Item label={'Cantidad'} name="qty" rules={[{ required: true }]} extra={controller.shippingHelperText}>
                <InputNumber onChange={controller.onShippingQtyChange} />
            </Form.Item>
        </ModalForm>
    );

    // Render
    return (
        <div className={'sale-detail'}>
            <PageHeader title={controller.title} onBack={history.goBack} />
            <Space direction={'vertical'} style={{ width: '100%' }}>
                <div className={'payment-layout'}>
                    {controller.isLoadingPayment ? <Spin /> : controller.paymentData ? PaymentData : PaymentButton}
                </div>

                <div className={'shipment-layout'}>
                    {controller.isLoadingShipping ? <Spin /> : controller.shippingData ? ShippingData : ShippingButton}
                </div>
            </Space>
            {AddPaymentModal}
            {AddShippingModal}
        </div>
    );
};
