import { useLocalSession } from 'tools/session/session.hooks';
import { DolibarrOrdersService } from 'services/orders/dolibarr-orders.service';
import { useDolibarrInvoicesService } from 'services/invoices/invoices.service';

export class OrderDto {
    id: number;
    concept: string;
    date: Date;
    statusId: number;
    status: string;
    statusColor: string;
    amount: number;
    invoiceId?: number;
    productName?: string;
    productId?: number;
}

export class SaleProductDto {
    id: string;
    name: string;
    price: number;
}

export class SubProductDto {
    rowid: number;
    label: string;
    qty: number;
    incdec: number;
}

export interface CreateOrderInput {
    date: Date;
    clientName: string;
    productId: number;
    quantity: number;
    price: number;
}

export interface UpdateOrderInput {
    statut: number; //0 = draft, 1 = nuevo, 2 = enviado (falta pagar), 3 cerrado (pagado y enviado), 4 paado (falta enviar)
}

export interface CreateStockMovementInput {
    product_id: number;
    qty: number;
    warehouse_id: number;
    lot: string;
    order_id: number;
    label: string;
    invoice_id: number | undefined;
}

export interface WarehouseDto {
    id: number;
    name: string;
}

export interface StockMovementDto {
    qty: number;
    date: Date;
    batch: string;
    label: string;
}

export const DEFAULT_WAREHOUSE_ID: unknown = '12';
export const DEFAULT_ACCOUNT_ID: unknown = '11';

export interface OrdersService {
    getAllOrders: () => Promise<OrderDto[]>;
    getOneOrder: (id: number) => Promise<OrderDto>;
    getProductsForSale: () => Promise<SaleProductDto[]>;
    getSubProducts: (productId: number) => Promise<SubProductDto[]>;
    createOrder: (input: CreateOrderInput) => Promise<number>;
    updateOneOrder: (id: number, input: UpdateOrderInput) => Promise<OrderDto>;
    getStockMovements: (orderId: number) => Promise<StockMovementDto[]>;
    createStockMovement: (input: CreateStockMovementInput) => Promise<void>;
    getWarehousesForSale: () => Promise<WarehouseDto[]>;
}

export const useDolibarrOrdersService = (): OrdersService => {
    const [getSession] = useLocalSession();
    const session = getSession();
    const invoicesService = useDolibarrInvoicesService();
    return new DolibarrOrdersService(session, invoicesService);
};
