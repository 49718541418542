import React from 'react';
import 'fragments/bank-accounts/bank-accounts.scss';
import { BankAccountItemView, BankAccountsFragmentProps } from 'fragments/bank-accounts/interfaces';
import { useBankAccountsController } from 'fragments/bank-accounts/bank-accounts.controller';
import { PageHeader, Table } from 'antd';
import { useTranslator } from 'view-hooks/translator-hook';
import { ColumnType } from 'antd/lib/table';
import SimpleItemView from 'components/simple-item-view/simple-item-view.component';

export const BankAccountsFragment: React.FC<BankAccountsFragmentProps> = (props) => {
    const { useController = useBankAccountsController } = props;
    const controller = useController();
    const { translate } = useTranslator();

    const renderItem = (text: string, item: BankAccountItemView): React.ReactElement => {
        return <SimpleItemView title={item.title} subtitle={`$ ${item.balance}`} />;
    };

    const columns: ColumnType<BankAccountItemView>[] = [
        {
            title: translate({ key: 'bank-accounts-fragment.title' }),
            render: renderItem,
        },
    ];

    // Render
    return (
        <div className={'bank-accounts'}>
            <PageHeader title={translate({ key: 'bank-accounts-fragment.title' })} />
            <Table loading={false} columns={columns} dataSource={controller.accountOptions} />
        </div>
    );
};
