import React from 'react';
import 'fragments/sales/sales.scss';
import { SaleItemView, SalesFragmentProps } from 'fragments/sales/interfaces';
import { useSalesController } from 'fragments/sales/sales.controller';
import { Button, PageHeader, Table, Tag, Typography, Form, Input, Select, InputNumber, Space } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import { useTranslator } from 'view-hooks/translator-hook';
import { ColumnType } from 'antd/lib/table';
import SimpleItemView from 'components/simple-item-view/simple-item-view.component';
import ModalForm from 'components/modal-form/modal-form.component';

export const SalesFragment: React.FC<SalesFragmentProps> = (props) => {
    const { useController = useSalesController } = props;
    const controller = useController();
    const { translate } = useTranslator();
    const [form] = Form.useForm();

    const renderTitle = (text: string, item: SaleItemView): React.ReactElement => {
        return <SimpleItemView title={item.title} subtitle={item.date} />;
    };

    const renderStatus = (text: string, item: SaleItemView): React.ReactElement => {
        return <Tag color={item.statusColor}>{item.status}</Tag>;
    };

    const renderAmount = (text: string, item: SaleItemView): React.ReactElement => {
        return <Typography.Text>$ {item.amount}</Typography.Text>;
    };

    const columns: ColumnType<SaleItemView>[] = [
        {
            title: translate({ key: 'sales-fragment.sale-title-column-label' }),
            dataIndex: 'title',
            key: 'title',
            render: renderTitle,
        },
        {
            title: translate({ key: 'sales-fragment.sale-status-column-label' }),
            dataIndex: 'status',
            key: 'status',
            render: renderStatus,
        },
        {
            title: translate({ key: 'sales-fragment.sale-amount-column-label' }),
            dataIndex: 'amount',
            key: 'amount',
            render: renderAmount,
        },
    ];

    // Render
    return (
        <div className={'sales'}>
            <PageHeader
                title={translate({ key: 'sales-fragment.title' })}
                extra={[
                    <Button
                        type={'primary'}
                        size={'large'}
                        key="filter"
                        icon={<FilterOutlined />}
                        onClick={controller.onFilterButtonPressed}
                    />,
                    <Button type={'primary'} size={'large'} key="add" onClick={controller.onAddButtonPressed}>
                        {translate({ key: 'general.add' })}
                    </Button>,
                ]}
            />
            <Table
                loading={controller.isLoading}
                columns={columns}
                dataSource={controller.visibleItems}
                onRow={(item) => ({
                    onClick: () => controller.onSaleSelected(item.id),
                })}
            />
            <ModalForm
                form={form}
                layout={'vertical'}
                isVisible={controller.isAddModalVisible}
                isLoading={controller.isAddModalLoading}
                onCancel={controller.onCloseAddModalPressed}
                onFinish={controller.onAddSale}
            >
                <Form.Item
                    label={translate({ key: 'add-sale-fragment.product-label' })}
                    name="productId"
                    initialValue={controller.productInput}
                    rules={[{ required: true }]}
                >
                    <Select
                        options={controller.productOptions}
                        onChange={(value) => {
                            controller.onProductInputChange(value as number);
                        }}
                    >
                        {controller.productOptions.map((option) => {
                            return (
                                <Select.Option key={option.key} value={option.value}>
                                    {option.label}
                                </Select.Option>
                            );
                        })}
                    </Select>
                </Form.Item>

                <Space align={'center'}>
                    <Form.Item
                        label={translate({ key: 'add-sale-fragment.quantity-label' })}
                        name="quantity"
                        initialValue={controller.quantityInput}
                        rules={[{ required: true }]}
                    >
                        <InputNumber
                            min={1}
                            max={100}
                            onChange={(value) => controller.onQuantityInputChange(value as number)}
                        />
                    </Form.Item>

                    <Form.Item
                        label={'Precio Unitario'}
                        initialValue={controller.priceInput}
                        rules={[{ required: true }]}
                    >
                        <InputNumber
                            min={0}
                            value={controller.priceInput}
                            onChange={(value) => controller.onPriceInputChange(value as number)}
                        />
                    </Form.Item>
                </Space>

                <Form.Item
                    label={translate({ key: 'add-sale-fragment.client-name-label' })}
                    name="name"
                    initialValue={controller.clientNameInput}
                    rules={[{ required: true }]}
                >
                    <Input
                        autoComplete={'off'}
                        onPressEnter={form.submit}
                        onChange={(e) => controller.onClientNameInputChange(e.target.value)}
                    />
                </Form.Item>
            </ModalForm>
            <ModalForm
                form={form}
                isLoading={false}
                layout={'vertical'}
                isVisible={controller.isFilterVisible}
                onCancel={controller.onCancelFilterButtonPressed}
                onFinish={controller.onFilterSubmitted}
            >
                <Form.Item
                    label={translate({ key: 'sales-fragment.filter-status-label' })}
                    name="status"
                    initialValue={0}
                    rules={[{ required: true }]}
                >
                    <Select>
                        <Select.Option key={0} value={0}>
                            {translate({ key: 'sales-fragment.sale-status.all' })}
                        </Select.Option>
                        <Select.Option key={0} value={1}>
                            {translate({ key: 'sales-fragment.sale-status.new' })}
                        </Select.Option>
                        <Select.Option key={0} value={2}>
                            {translate({ key: 'sales-fragment.sale-status.toPay' })}
                        </Select.Option>
                        <Select.Option key={0} value={4}>
                            {translate({ key: 'sales-fragment.sale-status.toShip' })}
                        </Select.Option>
                    </Select>
                </Form.Item>
            </ModalForm>
        </div>
    );
};
