import axios from 'axios';
import { REACT_APP_API_BASE_URL } from 'constants/env';
import {
    CreateOrderInput,
    OrderDto,
    SaleProductDto,
    OrdersService,
    CreateStockMovementInput,
    WarehouseDto,
    StockMovementDto,
    UpdateOrderInput,
    SubProductDto,
} from 'services/orders/orders.service';
import { Session } from 'tools/session/session';
import { InvoicesService } from 'services/invoices/invoices.service';

export class DolibarrOrdersService implements OrdersService {
    constructor(private session: Session, private invoicesService: InvoicesService) {}

    async getAllOrders(): Promise<OrderDto[]> {
        const response = await axios.get(REACT_APP_API_BASE_URL + '/orders', {
            headers: { DOLAPIKEY: this.session.getToken() },
            params: {
                sortfield: 't.rowid',
                sortorder: 'DESC',
            },
        });
        return response.data.map(this.mapResponseToOrder);
    }

    async getOneOrder(id: number): Promise<OrderDto> {
        const response = await axios.get(REACT_APP_API_BASE_URL + `/orders/${id}`, {
            headers: { DOLAPIKEY: this.session.getToken() },
        });
        return this.mapResponseToOrder(response.data);
    }

    async updateOneOrder(id: number, input: UpdateOrderInput): Promise<OrderDto> {
        const response = await axios.put(REACT_APP_API_BASE_URL + `/orders/${id}`, input, {
            headers: { DOLAPIKEY: this.session.getToken() },
        });
        return this.mapResponseToOrder(response.data);
    }

    mapResponseToOrder = (item: any): OrderDto => {
        const productName = item.lines && item.lines.length > 0 ? item.lines[0].product_label : undefined;
        const productId = item.lines && item.lines.length > 0 ? item.lines[0].fk_product : undefined;
        const statusLabels = ['borrador', 'a pagar/entregar', 'a pagar', 'cerrada', 'a entregar'];
        const statusColors = ['default', 'red', 'orange', 'green', 'orange'];
        return {
            id: item.id,
            amount: Number(item.total_ht) ?? 0,
            concept: item.note_private ?? '',
            date: new Date(item.date * 1000),
            statusId: item.statut,
            status: statusLabels[item.statut],
            statusColor: statusColors[item.statut],
            invoiceId: parseInt(item.ref_ext) || undefined,
            productName: productName,
            productId: productId,
        };
    };

    async getProductsForSale(): Promise<SaleProductDto[]> {
        const response = await axios.get(REACT_APP_API_BASE_URL + '/products', {
            headers: { DOLAPIKEY: this.session.getToken() },
            params: {
                sqlfilters: '(t.tosell:=:1)',
            },
        });
        return response.data.map(
            (item: any): SaleProductDto => {
                return {
                    id: item.id,
                    name: item.label,
                    price: item.price,
                };
            },
        );
    }

    async createOrder(input: CreateOrderInput): Promise<number> {
        const data = {
            socid: 10,
            date: Math.round(input.date.getTime() / 1000),
            note_private: 'Venta a ' + input.clientName,
            lines: [
                {
                    fk_product: input.productId,
                    qty: input.quantity,
                    subprice: input.price,
                },
            ],
        };
        const response = await axios.post(REACT_APP_API_BASE_URL + '/orders', data, {
            headers: { DOLAPIKEY: this.session.getToken() },
        });
        const orderId = response.data;
        await this.validateOrder(orderId);
        const invoiceId = await this.invoicesService.createInvoiceFromOrder(orderId);
        await this.invoicesService.validateInvoice(invoiceId);
        await this.setOrderInvoiceId(orderId, invoiceId);
        await this.setOrderInvoiced(orderId);
        return response.data;
    }

    async updateOrder(orderId: number, data: any): Promise<{ id: number }> {
        const response = await axios.put(REACT_APP_API_BASE_URL + `/orders/${orderId}`, data, {
            headers: { DOLAPIKEY: this.session.getToken() },
        });
        return response.data;
    }

    async validateOrder(orderId: number): Promise<{ id: number }> {
        const response = await axios.post(REACT_APP_API_BASE_URL + `/orders/${orderId}/validate`, null, {
            headers: { DOLAPIKEY: this.session.getToken() },
        });
        return response.data;
    }

    async setOrderInvoiced(orderId: number): Promise<{ id: number }> {
        const response = await axios.post(REACT_APP_API_BASE_URL + `/orders/${orderId}/setinvoiced`, null, {
            headers: { DOLAPIKEY: this.session.getToken() },
        });
        return response.data;
    }

    async setOrderClosed(orderId: number): Promise<{ id: number }> {
        const response = await axios.post(
            REACT_APP_API_BASE_URL + `/orders/${orderId}/close`,
            { notrigger: 0 },
            {
                headers: { DOLAPIKEY: this.session.getToken() },
            },
        );
        return response.data;
    }

    async setOrderInvoiceId(orderId: number, invoiceId: number): Promise<number> {
        const data = {
            ref_ext: invoiceId,
        };
        const response = await axios.put(REACT_APP_API_BASE_URL + `/orders/${orderId}`, data, {
            headers: { DOLAPIKEY: this.session.getToken() },
        });
        return response.data;
    }

    async getStockMovements(orderId: number): Promise<StockMovementDto[]> {
        const response = await axios.get(REACT_APP_API_BASE_URL + `/stockmovements`, {
            headers: { DOLAPIKEY: this.session.getToken() },
            params: {
                sqlfilters: `(t.inventorycode:=:${orderId})`,
            },
        });
        return response.data.map(
            (item: any): StockMovementDto => {
                return {
                    batch: item.batch,
                    date: new Date(item.datem * 1000),
                    qty: item.qty,
                    label: item.label,
                };
            },
        );
    }

    async reduceStock(
        productId: number,
        warehouseId: number,
        qty: number,
        lot: string,
        movementCode: string,
        movementLabel: string,
    ): Promise<any> {
        const response = await axios.post(
            REACT_APP_API_BASE_URL + `/stockmovements`,
            {
                product_id: productId,
                warehouse_id: warehouseId,
                qty: -qty,
                lot: lot,
                movementcode: movementCode,
                movementlabel: movementLabel,
            },
            {
                headers: { DOLAPIKEY: this.session.getToken() },
            },
        );
        return response.data;
    }
    async getSubProducts(productId: number): Promise<SubProductDto[]> {
        const childProductsResponse = await axios.get(REACT_APP_API_BASE_URL + `/products/${productId}/subproducts`, {
            headers: { DOLAPIKEY: this.session.getToken() },
        });
        return childProductsResponse.data;
    }

    async createStockMovement(input: CreateStockMovementInput): Promise<void> {
        const childProducts = await this.getSubProducts(input.product_id);

        if (Array.isArray(childProducts) && childProducts.length > 0) {
            for (const product of childProducts) {
                await this.reduceStock(
                    product.rowid,
                    input.warehouse_id,
                    product.qty * product.incdec,
                    input.lot,
                    `${input.order_id}`,
                    `${input.label} | ${product.label}`,
                );
            }
        } else {
            await this.reduceStock(
                input.product_id,
                input.warehouse_id,
                input.qty,
                input.lot,
                `${input.order_id}`,
                input.label,
            );
        }

        await this.updateOrder(input.order_id, { note_private: `${input.label} | Lote #${input.lot}` });
        if (input.invoice_id) {
            await this.invoicesService.updateInvoice(input.invoice_id, {
                note_private: `${input.label} | Lote #${input.lot}`,
            });
        }
        await this.setOrderClosed(input.order_id);
    }

    async getWarehousesForSale(): Promise<WarehouseDto[]> {
        const response = await axios.get(REACT_APP_API_BASE_URL + `/warehouses`, {
            headers: { DOLAPIKEY: this.session.getToken() },
            params: {
                sqlfilters: '(t.fk_parent:=:1)',
            },
        });
        return response.data.map((item: any) => {
            return {
                id: item.id,
                name: item.label,
            };
        });
    }
}
