import { SaleItemView, SalesController } from 'fragments/sales/interfaces';
import { useEffect, useState } from 'react';
import { OrderDto, SaleProductDto, useDolibarrOrdersService } from 'services/orders/orders.service';
import { readableDate } from 'helpers/date.helper';
import { useMessenger } from 'view-hooks/messenger-hook';
import { LabeledValue } from 'components/labeled-value';
import { useHistory } from 'react-router-dom';

interface ProductLabeledValue extends LabeledValue {
    price: number;
}
export const useSalesController = (
    ordersService = useDolibarrOrdersService(),
    messenger = useMessenger(),
    history = useHistory(),
): SalesController => {
    /* State */
    const [isLoading, setIsLoading] = useState(false);
    const [isAddModalVisible, setIsAddModalVisible] = useState(false);
    const [isAddModalLoading, setIsAddModalLoading] = useState(false);

    const [productOptions, setProductOptions] = useState<ProductLabeledValue[]>([]);
    const [productInput, setProductInput] = useState<number>();
    const [quantityInput, setQuantityInput] = useState<number>(1);
    const [priceInput, setPriceInput] = useState<number>(0);
    const [clientNameInput, setClientNameInput] = useState('');

    const [items, setItems] = useState<SaleItemView[]>([]);
    const [filterStatus, setFilterStatus] = useState<number | undefined>();
    const [isFilterVisible, setIsFilterVisible] = useState<boolean>(false);

    /* Listeners */
    useEffect(() => {
        fetchOrders();
        fetchProductsOptions();
    }, []);

    /* View Events */
    const onAddButtonPressed = () => {
        setIsAddModalVisible(true);
    };

    const onFilterButtonPressed = () => {
        setIsFilterVisible(true);
    };

    const onCancelFilterButtonPressed = () => {
        setIsFilterVisible(false);
    };

    const onFilterSubmitted = (inputs: any) => {
        setFilterStatus(inputs.status);
        setIsFilterVisible(false);
    };

    const onAddSale = () => {
        const selectedProduct = productOptions.find((p) => p.value == productInput);
        if (!selectedProduct) {
            messenger.showErrorMessage({ key: 'No hay producto seleccionado' });
            return;
        }
        setIsAddModalLoading(true);
        ordersService
            .createOrder({
                clientName: clientNameInput,
                date: new Date(),
                price: priceInput,
                productId: selectedProduct.value as number,
                quantity: quantityInput,
            })
            .then((result) => {
                setIsAddModalLoading(false);
                messenger.showSuccessMessage({ key: 'Orden ' + result + ' creada' });
                goToDetail(result);
            })
            .catch(() => {
                setIsAddModalLoading(false);
                messenger.showErrorMessage({ key: 'Error creando la order' });
            });
    };

    const onCloseAddModalPressed = () => {
        setIsAddModalVisible(false);
    };

    const onClientNameInputChange = (value: string) => {
        setClientNameInput(value);
    };

    const onProductInputChange = (value: number) => {
        const selectedProduct = productOptions.find((p) => p.value == value);
        setProductInput(value);
        if (selectedProduct) {
            setPriceInput(selectedProduct.price);
        }
    };

    const onQuantityInputChange = (value: number) => {
        setQuantityInput(value);
    };

    const onPriceInputChange = (value: number) => {
        setPriceInput(value);
    };

    const onSaleSelected = (id: number) => {
        goToDetail(id);
    };

    /* Private Methods */
    const fetchOrders = () => {
        setIsLoading(true);
        ordersService
            .getAllOrders()
            .then((results) => {
                setIsLoading(false);
                setItems(results.map(mapOrderToItemView));
                setFilterStatus(undefined);
            })
            .catch(() => {
                messenger.showErrorMessage({ key: 'Error al traer los productos' });
                setIsLoading(false);
                setItems([]);
                setFilterStatus(undefined);
            });
    };

    const getVisibleItems = () => {
        if (!filterStatus || filterStatus == 0) {
            return items;
        }
        return items.filter((item) => {
            return item.statusId == filterStatus;
        });
    };

    const fetchProductsOptions = () => {
        ordersService.getProductsForSale().then((results) => {
            let options = results.map(mapSaleProductDtoToProductOption);
            options = options.sort((a, b) => {
                return b.price - a.price;
            });
            setProductOptions(options);
            if (options.length > 0) {
                setProductInput(options[0].value as number);
                setPriceInput(options[0].price);
            }
        });
    };

    const mapOrderToItemView = (dto: OrderDto): SaleItemView => {
        return {
            id: dto.id,
            amount: dto.amount,
            date: readableDate(dto.date),
            statusId: dto.statusId,
            status: dto.status,
            statusColor: dto.statusColor,
            title: dto.concept,
        };
    };

    const mapSaleProductDtoToProductOption = (dto: SaleProductDto): ProductLabeledValue => {
        return { key: dto.id, label: dto.name, value: dto.id, price: dto.price };
    };

    const goToDetail = (saleId: number) => {
        history.push('/sales/' + saleId);
    };

    // Return state and events
    return {
        onAddButtonPressed,
        onFilterButtonPressed,
        onCancelFilterButtonPressed,
        onFilterSubmitted,
        onAddSale,
        onCloseAddModalPressed,
        onProductInputChange,
        onQuantityInputChange,
        onPriceInputChange,
        onClientNameInputChange,
        onSaleSelected,
        visibleItems: getVisibleItems(),
        isLoading,
        isAddModalVisible,
        isAddModalLoading,
        productOptions,
        productInput,
        quantityInput,
        priceInput,
        clientNameInput,
        isFilterVisible,
    };
};
