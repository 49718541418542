import React from 'react';
import { NavBar, NavBarProps } from 'components/nav-bar/nav-bar.component';
import { useTranslator } from 'view-hooks/translator-hook';
import { SalesFragment } from 'fragments/sales/sales.fragment';
import { Switch, Route } from 'react-router-dom';
import { SaleDetailFragment } from 'fragments/sale-detail/sale-detail.fragment';
import { useLocalSession } from 'tools/session/session.hooks';
import { LoginFragment } from 'fragments/login/login.fragment';
import { BankAccountsFragment } from '../fragments/bank-accounts/bank-accounts.fragment';

export default function NavBarMain(): JSX.Element {
    const { translate } = useTranslator();
    const SalesStack = (
        <Switch>
            <Route exact path={'/sales'}>
                <SalesFragment />
            </Route>
            <Route path={`/sales/:id`}>
                <SaleDetailFragment />
            </Route>
        </Switch>
    );
    const config: NavBarProps = {
        screens: {
            sales: {
                title: translate({ key: 'sales-fragment.title' }),
                component: SalesStack,
            },
            bankAccounts: {
                title: translate({ key: 'bank-accounts-fragment.title' }),
                component: <BankAccountsFragment />,
            },
        },
        footer: translate({ key: 'footer.copyright-message' }),
    };

    return <NavBar {...config} />;
}
