import { MainController } from 'fragments/main/interfaces';

export const useMainController = (): /* <--Dependency Injections  like services hooks */ MainController => {
    /* State */
    // Ex. const [count, setCount] = useState(0);

    /* Hooks */
    // Ex. const [session] = useSession();

    /* Services */
    // Ex. const userService = useUserService();

    /* Listeners */
    // Ex. useEffect(() => { onSessionUpdate(); }, [session]);

    /* View Events */
    //Ex. const onIncreaseButtonPressed = () => {}

    /* Private Methods */
    //Ex. const increaseCount = () => {}

    // Return state and events
    return { example: 'example' };
};
