import { PaymentData, PaymentFormData, SaleDetailController, ShippingData } from 'fragments/sale-detail/interfaces';
import { useEffect, useState } from 'react';
import { LabeledValue } from 'components/labeled-value';
import { useDolibarrInvoicesService } from 'services/invoices/invoices.service';
import {
    CreateStockMovementInput,
    DEFAULT_ACCOUNT_ID,
    DEFAULT_WAREHOUSE_ID,
    SubProductDto,
    useDolibarrOrdersService,
} from 'services/orders/orders.service';
import { readableDate } from 'helpers/date.helper';
import { useDolibarrAccountsService } from 'services/accounts/accounts.service';
import { useMessenger } from 'view-hooks/messenger-hook';

interface AccountLabeledValue extends LabeledValue {
    type: number;
}

export const useSaleDetailController = (
    id: number,
    messenger = useMessenger(),
    ordersService = useDolibarrOrdersService(),
    invoicesService = useDolibarrInvoicesService(),
    accountsService = useDolibarrAccountsService(),
): SaleDetailController => {
    /* State */
    const [title, setTitle] = useState('Venta nro ' + id);
    const [orderStatus, setOrderStatus] = useState<number>();
    const [invoiceId, setInvoiceId] = useState<number>();
    const [productId, setProductId] = useState<number>();
    const [subProducts, setSubProducts] = useState<SubProductDto[]>([]);

    const [isLoadingPayment, setIsLoadingPayment] = useState(false);
    const [paymentData, setPaymentData] = useState<PaymentData>();
    const [isAddPaymentVisible, setIsAddPaymentVisible] = useState(false);
    const [isAddPaymentLoading, setIsAddPaymentLoading] = useState(false);
    const [accountOptions, setAccountOptions] = useState<AccountLabeledValue[]>([]);
    const [paymentFormData, setPaymentFormData] = useState<PaymentFormData>({});

    const [isLoadingShipping, setIsLoadingShipping] = useState(false);
    const [shippingData, setShippingData] = useState<ShippingData[]>();
    const [isAddShippingVisible, setIsAddShippingVisible] = useState(false);
    const [isAddShippingLoading, setIsAddShippingLoading] = useState(false);
    const [warehouseOptions, setWarehouseOptions] = useState<LabeledValue[]>([]);
    const [shippingHelperText, setShippingHelperText] = useState('');
    const [shippingQty, setShippingQty] = useState(1);
    const [shippingFormData, setShippingFormData] = useState<CreateStockMovementInput>({
        lot: '',
        order_id: id,
        product_id: 15,
        qty: 1,
        warehouse_id: 1,
        label: title,
        invoice_id: invoiceId,
    });

    /* Listeners */
    useEffect(() => {
        fetchOrder();
        fetchShipping();
        fetchAccounts();
        fetchWharehouses();
    }, []);

    useEffect(() => {
        updateShippingHelperText();
    }, [subProducts, shippingQty]);

    /* View Events */
    const onAddPaymentPressed = () => {
        setIsAddPaymentVisible(true);
    };

    const onAddPaymentSubmit = (data: PaymentFormData) => {
        const accountOption = accountOptions.find((o) => o.value == data.accountId);
        if (!accountOption || !invoiceId) {
            console.log('accountOption', accountOption);
            console.log('invoiceId', invoiceId);
            return;
        }
        setIsAddPaymentLoading(true);
        invoicesService
            .createPayment({
                orderId: id,
                accountId: accountOption.value as number,
                accountType: accountOption.type,
                date: new Date(),
                invoiceId: invoiceId,
            })
            .then((paymentId) => {
                setIsAddPaymentLoading(false);
                setIsAddPaymentVisible(false);
                updateOrderStatus(true, orderStatus == 2 || orderStatus == 3);
                messenger.showSuccessMessage({ key: 'El pago se creó correctamente con el nro ' + paymentId });
            })
            .catch(() => {
                setIsAddPaymentLoading(false);
                messenger.showErrorMessage({ key: 'Ocurrió un problema al crear el pago' });
            });
    };

    const onCloseAddPayment = () => {
        setIsAddPaymentVisible(false);
    };

    const onAddShippingPressed = () => {
        setIsAddShippingVisible(true);
    };

    const onAddShippingSubmit = (data: CreateStockMovementInput) => {
        const warehouseOption = warehouseOptions.find((o) => o.value == data.warehouse_id);
        if (!warehouseOption || !productId) {
            return;
        }
        setIsAddShippingLoading(true);
        console.log(data);
        ordersService
            .createStockMovement({
                ...data,
                qty: data.qty,
                order_id: id,
                product_id: productId,
                label: title,
                invoice_id: invoiceId,
            })
            .then(() => {
                setIsAddShippingLoading(false);
                setIsAddShippingVisible(false);
                fetchShipping();
                updateOrderStatus(orderStatus == 4 || orderStatus == 3, true);
                messenger.showSuccessMessage({ key: 'La entrega se creó correctamente ' });
            })
            .catch(() => {
                setIsAddShippingLoading(false);
                messenger.showErrorMessage({ key: 'Ocurrió un problema al crear la entrega' });
            });
    };

    const onCloseAddShipping = () => {
        setIsAddShippingVisible(false);
    };

    const onShippingQtyChange = (qty: number | string | undefined) => {
        if (!qty) return;
        setShippingQty(+qty);
    };

    /* Private Methods */
    const fetchOrder = () => {
        setIsLoadingPayment(true);
        ordersService
            .getOneOrder(id)
            .then((result) => {
                setTitle(result.concept);
                setOrderStatus(result.statusId);
                setProductId(result.productId);
                if (result.productId) {
                    fetchSubProducts(result.productId);
                }
                if (result.invoiceId) {
                    setInvoiceId(result.invoiceId);
                    fetchPayments(result.invoiceId);
                } else {
                    setInvoiceId(undefined);
                    setIsLoadingPayment(false);
                }
            })
            .catch(() => {
                messenger.showErrorMessage({ key: 'No se pudo obtener la orden' });
            });
    };

    const updateOrderStatus = (isPayed: boolean, isShipped: boolean) => {
        //0 = draft, 1 = nuevo, 2 = enviado (falta pagar), 3 cerrado (pagado y enviado), 4 paado (falta enviar)
        const statut = isPayed && isShipped ? 3 : isPayed && !isShipped ? 4 : !isPayed && isShipped ? 2 : 1;
        setIsLoadingPayment(true);
        ordersService
            .updateOneOrder(id, { statut })
            .then((result) => {
                setTitle(result.concept);
                setProductId(result.productId);
                setOrderStatus(result.statusId);
                if (result.invoiceId) {
                    setInvoiceId(result.invoiceId);
                    fetchPayments(result.invoiceId);
                } else {
                    setInvoiceId(undefined);
                    setIsLoadingPayment(false);
                }
            })
            .catch(() => {
                messenger.showErrorMessage({ key: 'No se pudo obtener la orden' });
            });
    };

    const fetchAccounts = () => {
        accountsService
            .getAllAccounts()
            .then((result) => {
                const options = result.map(
                    (item): AccountLabeledValue => {
                        return { key: '' + item.id, label: item.name, value: item.id, type: item.type };
                    },
                );
                setPaymentFormData({ accountId: DEFAULT_ACCOUNT_ID as number });
                setAccountOptions(options);
            })
            .catch(() => {
                messenger.showErrorMessage({ key: 'No se pudo obtener las cuentas' });
            });
    };

    const fetchWharehouses = () => {
        ordersService
            .getWarehousesForSale()
            .then((result) => {
                const options = result.map(
                    (item: any): LabeledValue => {
                        return { key: '' + item.id, label: item.name, value: item.id };
                    },
                );
                if (options.length > 0) {
                    setShippingFormData({
                        ...shippingFormData,
                        warehouse_id: DEFAULT_WAREHOUSE_ID as number,
                    });
                }
                setWarehouseOptions(options);
            })
            .catch(() => {
                messenger.showErrorMessage({ key: 'No se pudo obtener los almacenes' });
            });
    };

    const fetchPayments = (invoiceId: number) => {
        invoicesService
            .getInvoicePayments(invoiceId)
            .then((result) => {
                setIsLoadingPayment(false);
                if (result.length > 0) {
                    const payment = result[0];
                    setPaymentData({
                        accountName: payment.accountName,
                        amount: payment.amount,
                        date: readableDate(payment.date),
                    });
                }
            })
            .catch(() => {
                setIsLoadingPayment(false);
            });
    };

    const fetchSubProducts = async (productId: number) => {
        const subProducts = await ordersService.getSubProducts(productId);
        setSubProducts(subProducts);
    };

    const updateShippingHelperText = () => {
        let helperText = subProducts.length > 0 ? 'Se entregan: ' : '';
        for (let i = 0; i < subProducts.length; i++) {
            const subProduct = subProducts[i];
            helperText += `${subProduct.qty * shippingQty} ${subProduct.label}`;
            if (i < subProducts.length - 1) {
                helperText += ' + ';
            }
        }
        setShippingHelperText(helperText);
    };

    const fetchShipping = () => {
        setIsLoadingShipping(true);
        ordersService
            .getStockMovements(id)
            .then((result) => {
                const data = result.map(
                    (item): ShippingData => {
                        return {
                            batch: item.batch,
                            date: readableDate(item.date),
                            quantity: item.qty,
                            label: item.label,
                        };
                    },
                );
                setShippingData(data);
                setIsLoadingShipping(false);
            })
            .catch(() => {
                setIsLoadingShipping(false);
            });
    };
    // Return state and events
    return {
        isAddPaymentVisible,
        isAddPaymentLoading,
        title,
        isLoadingPayment,
        paymentData,
        accountOptions,
        paymentFormData,
        onAddPaymentPressed,
        onAddPaymentSubmit,
        onCloseAddPayment,
        isLoadingShipping,
        shippingData,
        isAddShippingVisible,
        isAddShippingLoading,
        warehouseOptions,
        shippingFormData,
        onAddShippingPressed,
        onAddShippingSubmit,
        onCloseAddShipping,
        shippingHelperText,
        onShippingQtyChange,
    };
};
