import React, { useState } from 'react';
import 'fragments/login/login.scss';
import { LoginFragmentProps } from 'fragments/login/interfaces';
import { useLoginController } from 'fragments/login/login.controller';
import { Button, Form, Image, Input } from 'antd';
import logo from 'assets/images/logo.png';
import { useLocalSession } from 'tools/session/session.hooks';
import axios from 'axios';
import { REACT_APP_API_BASE_URL } from 'constants/env';
import { Session } from 'tools/session/session';
import { useMessenger } from 'view-hooks/messenger-hook';

export const LoginFragment: React.FC<LoginFragmentProps> = (props) => {
    const { useController = useLoginController } = props;
    const controller = useController();
    const [, setSession] = useLocalSession();
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const messenger = useMessenger();

    const onLogin = async (values: any) => {
        setIsLoading(true);
        try {
            const response = await axios.post(REACT_APP_API_BASE_URL + `/login`, values);
            const session = new Session(response.data.success.token);
            setSession(session);
            setIsLoading(false);
        } catch {
            setIsLoading(false);
            messenger.showErrorMessage({ key: 'Ocurrió un error al ingresar' });
        }
    };
    // Render
    return (
        <div className={'login'}>
            <Image width={100} src={logo} />
            <Form form={form} onFinish={onLogin}>
                <Form.Item label={'Usuario'} name="login" rules={[{ required: true }]}>
                    <Input autoComplete={'off'} />
                </Form.Item>
                <Form.Item label={'Password'} name="password" rules={[{ required: true }]}>
                    <Input.Password autoComplete={'off'} />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" block loading={isLoading}>
                        Ingresar
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};
