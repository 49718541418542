import React from 'react';
import 'fragments/main/main.scss';
import { MainFragmentProps } from 'fragments/main/interfaces';
import { useMainController } from 'fragments/main/main.controller';
import { useLocalSession } from 'tools/session/session.hooks';
import NavBarMain from 'navigators/nav-bar-main';
import { LoginFragment } from 'fragments/login/login.fragment';

export const MainFragment: React.FC<MainFragmentProps> = (props) => {
    const { useController = useMainController } = props;
    const controller = useController();
    const [getSession] = useLocalSession();
    const session = getSession();

    // Render
    return session.getToken() ? <NavBarMain /> : <LoginFragment />;
};
