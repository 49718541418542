import { useLocalSession } from 'tools/session/session.hooks';
import { DolibarrAccountsService } from 'services/accounts/dolibarr-accounts.service';

export class AccountDto {
    id: number;
    name: string;
    type: number;
    balance: number;
}

export interface AccountsService {
    getAllAccounts: () => Promise<AccountDto[]>;
}

export const useDolibarrAccountsService = (): AccountsService => {
    const [getSession] = useLocalSession();
    const session = getSession();
    return new DolibarrAccountsService(session);
};
