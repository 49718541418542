import axios from 'axios';
import { REACT_APP_API_BASE_URL } from 'constants/env';
import { Session } from 'tools/session/session';
import { CreatePaymentInput, InvoicesService, PaymentDto } from 'services/invoices/invoices.service';

export class DolibarrInvoicesService implements InvoicesService {
    constructor(private session: Session) {}

    async createInvoiceFromOrder(orderId: number): Promise<number> {
        const response = await axios.post(REACT_APP_API_BASE_URL + `/invoices/createfromorder/${orderId}`, null, {
            headers: { DOLAPIKEY: this.session.getToken() },
        });
        return response.data.id;
    }

    async validateInvoice(invoiceId: number): Promise<number> {
        const response = await axios.post(REACT_APP_API_BASE_URL + `/invoices/${invoiceId}/validate`, null, {
            headers: { DOLAPIKEY: this.session.getToken() },
        });
        return response.data.id;
    }

    async updateInvoice(invoiceId: number, data: any): Promise<number> {
        const response = await axios.put(REACT_APP_API_BASE_URL + `/invoices/${invoiceId}`, data, {
            headers: { DOLAPIKEY: this.session.getToken() },
        });
        return response.data.id;
    }

    async getInvoicePayments(invoiceId: number): Promise<PaymentDto[]> {
        const response = await axios.get(REACT_APP_API_BASE_URL + `/invoices/${invoiceId}/payments`, {
            headers: { DOLAPIKEY: this.session.getToken() },
            params: {
                sortfield: 't.rowid',
                sortorder: 'DESC',
            },
        });
        return response.data.map(
            (item: any): PaymentDto => {
                return {
                    amount: Number(item.amount) ?? 0,
                    date: new Date(item.date),
                    accountName: item.ref,
                };
            },
        );
    }

    async createPayment(input: CreatePaymentInput): Promise<number> {
        const data = {
            datepaye: Math.round(input.date.getTime() / 1000),
            closepaidinvoices: 'yes',
            accountid: input.accountId,
            paiementid: input.accountType == 2 ? 4 : input.accountType,
        };
        const response = await axios.post(REACT_APP_API_BASE_URL + `/invoices/${input.invoiceId}/payments`, data, {
            headers: { DOLAPIKEY: this.session.getToken() },
        });
        return response.data;
    }
}
