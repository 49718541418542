import { useLocalSession } from 'tools/session/session.hooks';
import { DolibarrInvoicesService } from 'services/invoices/dolibarr-invoices.service';

export class PaymentDto {
    date: Date;
    accountName: string;
    amount: number;
}

export interface CreatePaymentInput {
    orderId: number;
    invoiceId: number;
    date: Date;
    accountId: number;
    accountType: number;
}

export interface InvoicesService {
    createInvoiceFromOrder: (orderId: number) => Promise<number>;
    validateInvoice: (invoiceId: number) => Promise<number>;
    updateInvoice: (invoiceId: number, data: any) => Promise<number>;
    getInvoicePayments: (invoiceId: number) => Promise<PaymentDto[]>;
    createPayment: (input: CreatePaymentInput) => Promise<number>;
}

export const useDolibarrInvoicesService = (): InvoicesService => {
    const [getSession] = useLocalSession();
    const session = getSession();
    return new DolibarrInvoicesService(session);
};
