import { BankAccountItemView, BankAccountsController } from 'fragments/bank-accounts/interfaces';
import { useDolibarrAccountsService } from '../../services/accounts/accounts.service';
import { LabeledValue } from '../../components/labeled-value';
import { useMessenger } from '../../view-hooks/messenger-hook';
import { useEffect, useState } from 'react';
interface AccountLabeledValue extends LabeledValue {
    type: number;
    balance: number;
}
export const useBankAccountsController = (
    messenger = useMessenger(),
    accountsService = useDolibarrAccountsService(),
): BankAccountsController => {
    /* State */
    const [accountOptions, setAccountOptions] = useState<BankAccountItemView[]>([]);

    /* Hooks */
    useEffect(() => {
        fetchAccounts();
    }, []);

    /* Services */
    // Ex. const userService = useUserService();

    /* Listeners */
    // Ex. useEffect(() => { onSessionUpdate(); }, [session]);

    /* View Events */
    //Ex. const onIncreaseButtonPressed = () => {}

    /* Private Methods */
    const fetchAccounts = () => {
        accountsService
            .getAllAccounts()
            .then((result) => {
                const options = result.map(
                    (item): BankAccountItemView => {
                        return {
                            id: '' + item.id,
                            title: item.name,
                            balance: item.balance,
                        };
                    },
                );
                setAccountOptions(options);
            })
            .catch(() => {
                messenger.showErrorMessage({ key: 'No se pudo obtener las cuentas' });
            });
    };

    // Return state and events
    return { accountOptions };
};
