export class Session {
    private readonly expirationTimestamp?: number;

    constructor(private token?: string) {}

    getToken(): string | undefined {
        return this.token;
    }

    isExpired(): boolean {
        if (!this.expirationTimestamp) {
            return false;
        }
        const todayTimestamp = Math.floor(Date.now() / 1000);
        return this.expirationTimestamp < todayTimestamp;
    }

    isAuthenticated(): boolean {
        return !!this.token;
    }
}
